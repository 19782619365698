<template>
  <section :id="id" :class="`text ${cls}`">
    <div class="text_text typo-rhythm typo-format" :class="`-${alignment}`">
      <StructuredText v-if="text.value?.document" :data="<any>text" :render-link-to-record="renderLinkToRecord" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { h } from 'vue';
import { StructuredText } from 'vue-datocms';
import './text.css';
import type { TText } from './text.type.js';

const props = withDefaults(defineProps<TText>(), {})
const { path } = usePath();

function renderLinkToRecord(api: any) {

  const finalPath = path(api.record.slug, {
    type: api.record.__typename,
  });

  const vnode = h(
    'a',
    {
      class: 'link',
      title: api.record.title,
      href: finalPath,
      target: api.transformedMeta?.target || '_self',
      rel: 'noopener noreferrer',
      onClick: (e: Event) => {
        if (api.transformedMeta?.target !== '_blank' && !api.record.slug.startsWith('http')) {
          e.preventDefault()
          navigateTo(finalPath, {
            external: api.transformedMeta?.target === '_blank',
          })
        }
      },
    },
    api.children,
  )
  return vnode
}

const id = props.advanced?.id ?? ''
const cls = props.advanced?.class ?? ''
</script>